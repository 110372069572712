<!--

      This component defines the card of a recent order in the recent orders
      list of a user's profile.

 -->

<template>
  <div class="order-card" @click="goToOrderDetails()">
    <div>
      <div class="secondary-text-gray">{{ date }}</div>
      <div class="secondary-text" v-if="!order.delivered">noch unterwegs</div>
    </div>
    <Price :price="price" />
    <div class="btn-secondary-small">Ansehen</div>
  </div>
</template>

<script>
import Price from '@/components/Price.vue'

export default {
  name: 'ordercard',
  props: ['order'],
  components: {
    Price
  },
  data () {
    return {
      image_cdn: process.env.VUE_APP_IMAGE_CDN,
      image_filter: process.env.VUE_APP_IMAGE_FILTER
    }
  },
  computed: {
    date () {
      const d = new Date(this.order.ordered_at)
      const today = new Date()
      const monthNames = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni',
        'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember']
      let date = ''
      if (today - d > 1000 * 60 * 60 * 24 * 365) {
        date = d.getDate() + '. ' + monthNames[d.getMonth()] + ' ' + d.getFullYear()
      } else {
        date = d.getDate() + '. ' + monthNames[d.getMonth()]
      }
      return date
    },
    price () {
      if (this.order && this.order.price) {
        return this.order.price
      }
    }
  },
  methods: {
    goToOrderDetails () {
      if (this.order && this.order.id) {
        this.$store.dispatch('save', { key: 'order', value: this.order }).then(() => {
          this.$store.dispatch('goTo', 'order')
        })
      }
    }
  },
  mounted () {
    if (!this.order.delivered && this.$store.state.deliveredOrders) {
      this.order.delivered = this.$store.state.deliveredOrders.some(o => o.id === this.order.id)
    }
  }
}
</script>

<style lang="scss" scoped>
@import './../../theme/main.scss';

.order-card {
  background: white;
  display: grid;
  grid-template-columns: 2fr 1fr 90px;
  text-align: left;
  align-items: center;
  padding: 10px 15px;
  margin: 20px 20px 0 20px;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
}
</style>
