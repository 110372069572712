<!--

      This view displays the list of recent orders. It is reachable from within
      the profile tab.

 -->

<template>
  <Page title='Meine Bestellungen'>
    <NewCard v-if="hasNotYetOrdered" goTo="recipes" icon="addOutline">
      Du hast noch nie bestellt. Jetzt Menü planen und bestellen!
    </NewCard>
    <div v-if="visibleData && visibleData.length" :key="visibleData">
      <OrderCard
        v-for="(order, index) in visibleData"
        :key="order.id"
        :order="order"
        :index="index"
      />
      <ion-infinite-scroll
        @ionInfinite="this.loadNextPage($event)" 
        threshold="300px"
      >
        <ion-infinite-scroll-content
          class="infinite-scrolling-loading"
          loading-spinner="bubbles">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </div>
  </Page>
</template>

<script>
import Page from '@/components/Page.vue'
import OrderCard from '@/components/cards/OrderCard.vue'
import NewCard from '@/components/cards/NewCard.vue'


import {
  IonInfiniteScroll,
  IonInfiniteScrollContent
} from '@ionic/vue'

export default {
  name: 'orders',
  components: {
    Page,
    OrderCard,
    NewCard,
    IonInfiniteScroll,
    IonInfiniteScrollContent
  },
  data () {
    return {
      visibleData: [],
      lastResultsObj: null
    }
  },
  computed: {
    hasNotYetOrdered () {
      return !this.$store.state.orders || !this.$store.state.orders.count
    }
  },
  methods: {
    loadNextPage (event) {
      if (this.lastResultsObj) {
        const details = {
          url: this.lastResultsObj.next
        }
        this.$store.dispatch('apiSend', details ).then( nextpage => {
          if (nextpage && nextpage.count) {
            this.manageNewResults(nextpage)
            event.target.complete()
            if (!nextpage.next) {
              event.target.disabled = true
            }
          }
        })
      }
    },
    manageNewResults (results) {
      this.lastResultsObj = results
      this.visibleData = this.visibleData.concat(results.results)
    },
  },
  async mounted () {
    if (!this.$store.state.orders || !this.$store.state.orders.hasOwnProperty('results')) {
      await this.$store.dispatch('getMyLastOrders')
    }
    if (this.$store.state.orders && this.$store.state.orders.hasOwnProperty('results')) {
      this.manageNewResults(this.$store.state.orders)
    }
  }
}
</script>
